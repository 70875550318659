import { Center } from "@chakra-ui/react";
import { SignIn } from "@clerk/remix";
import { getAuth } from "@clerk/remix/ssr.server";
import { redirect } from "@remix-run/node";
import type { LoaderArgs, TypedResponse } from "@remix-run/node";
// import { ExternalLink } from "~/ui/navigation/external-link";

export async function loader(args: LoaderArgs): Promise<TypedResponse<never> | null> {
  const { userId } = await getAuth(args);
  if (userId) {
    return redirect("/after-login");
  }

  return null;
}

// eslint-disable-next-line import/no-default-export -- Remix requires default export
export default function Login(): JSX.Element {
  return (
    <Center h="80%">
      <div className="flex flex-col">
        <SignIn
          appearance={{
            elements: {
              //  footerAction__signIn: "hidden",
              card: "shadow-none",
            },
          }}
          path="/login"
          redirectUrl="/after-login"
          routing="path"
        />
        {/* <div className="text-center text-gray-600">
          No account yet?{" "}
          <ExternalLink href="https://calendly.com/get-double/intro-to-double">
            Book a demo
          </ExternalLink>
        </div> */}
      </div>
    </Center>
  );
}
